<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Profile
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component title="Edit Profile" icon="account-circle" class="tile is-child">
          <form @submit.prevent="submit">
            <b-field :label="noidLabel">
              <b-input
                type="number"
                :placeholder="noidLabel"
                v-model="form.noid"
                required
              />
            </b-field>
            <b-field label="Nama">
              <b-input
                v-model="form.name"
                placeholder="e.g. John Doe"
                required
              />
            </b-field>
            <b-field label="Email">
              <b-input
                type="email"
                v-model="form.email"
                placeholder=""
                required
              />
            </b-field>
            <b-field label="No. HP">
              <b-input
                type="number"
                v-model="form.phone"
                required
              />
            </b-field>
            <div v-if="currentRole === 'mahasiswa'">
              <b-field label="No. KTP">
                <b-input
                  type="number"
                  v-model="form.no_ktp"
                  required
                />
              </b-field>
              <b-field label="Tempat Lahir">
                <b-input
                  type="text"
                  v-model="form.birth_place"
                  required
                />
              </b-field>
              <b-field label="Tanggal Lahir">
                <b-datepicker
                    v-model="form.birth_date"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    icon-right-clickable
                    trap-focus
                    required>
                </b-datepicker>
              </b-field>
              <b-field label="Jenis Kelamin">
                <div class="block">
                  <b-radio v-model="form.gender"
                      name="gender"
                      native-value="laki-laki">
                      Laki Laki
                  </b-radio>
                  <b-radio v-model="form.gender"
                      name="gender"
                      native-value="perempuan">
                      Perempuan
                  </b-radio>
                </div>
              </b-field>
              <hr />
              <b-field label="Status Mahasiswa">
                <div class="block">
                  <b-radio v-model="form.status_mahasiswa"
                      name="status_mahasiswa"
                      native-value="baru">
                      baru
                  </b-radio>
                  <b-radio v-model="form.status_mahasiswa"
                      name="status_mahasiswa"
                      native-value="pindahan">
                      pindahan
                  </b-radio>
                  <b-radio v-model="form.status_mahasiswa"
                      name="status_mahasiswa"
                      native-value="lanjutan">
                      lanjutan
                  </b-radio>
                </div>
              </b-field>
              <b-field label="Asal Perguruan Tinggi" v-if="form.status_mahasiswa == 'pindahan' || form.status_mahasiswa == 'lanjutan'">
                <b-input
                  type="text"
                  v-model="form.asal_kampus"
                />
              </b-field>
              <b-field label="Tahun Masuk" v-if="form.status_mahasiswa == 'pindahan' || form.status_mahasiswa == 'lanjutan'">
                <b-input
                  type="number"
                  v-model="form.tahun_masuk_kampus_asal"
                />
              </b-field>
              <b-field label="Asal SMA/SMK">
                <b-input
                  type="text"
                  v-model="form.asal_sma"
                />
              </b-field>

              <hr>
              <b-field label="Alamat Lengkap">
                  <b-input maxlength="200" type="textarea" v-model="form.address"></b-input>
              </b-field>
              <hr>
              <b-field label="Link Berkas KTP" horizontal>
                <b-input
                  type="text"
                  v-model="form.berkas_ktp"
                />
              </b-field>
              <b-field label="Link Berkas Ijazah Terakhir" horizontal>
                <b-input
                  type="text"
                  v-model="form.berkas_ijazah"
                />
              </b-field>
            </div>

            <hr>
            <b-field horizontal label="Tanda Tangan" v-if="currentRole === 'dosen'">
              <b-upload v-model="file">
                <a class="button is-primary">
                  <b-icon icon="upload" custom-size="default"></b-icon>
                  <span>{{ buttonLabel }}</span>
                </a>
              </b-upload>
              <span v-if="file" class="file-name">{{ file.name }}</span>
              <user-avatar
                v-if="form.sign"
                :avatar="form.sign"
                class="image has-max-width is-aligned-center"
              />
            </b-field>
            <hr />
            <b-field horizontal>
              <div class="control">
                <button
                  type="submit"
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                >
                  Submit
                </button>
              </div>
            </b-field>
          </form>
        </card-component>
      </tiles>
      <password-update-form />
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import PasswordUpdateForm from '@/components/PasswordUpdateForm'
import Tiles from '@/components/Tiles'
import UserAvatar from '@/components/UserAvatar'
import axios from 'axios'

export default {
  name: 'Profile',
  components: {
    UserAvatar,
    Tiles,
    PasswordUpdateForm,
    HeroBar,
    TitleBar,
    CardComponent
  },
  data () {
    return {
      form: {},
      file: null,
      isFileUploaded: false,
      isLoading: false,
      data: false
    }
  },
  created () {
    const app = this

    axios
      .get('/whoami')
      .then((r) => {
        console.log(r.data)
        if (r.data) {
          this.form = r.data.data
          app.data = r.data.data

          if (app.form.birth_date) {
            app.form.birth_date = new Date(app.form.birth_date)
          }

          this.isProfileExists = true
        }
      })
      .catch((e) => {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          queue: false
        })
      })
  },
  computed: {
    noidLabel () {
      if (this.data && this.data.role === 'mahasiswa') {
        return 'NIM'
      }

      if (this.data && this.data.role === 'dosen') {
        return 'NID'
      }

      return 'NIP'
    },
    buttonLabel () {
      return !this.file ? 'Pick a file' : 'Pick another file'
    },
    user_id () {
      return this.$store.state.user.user.id
    },
    titleStack () {
      return ['Admin', 'Profile']
    },
    currentRole () {
      console.log(this.$store.getters.currentRole)
      return this.$store.getters.currentRole
    }

  },
  methods: {
    submit () {
      const data = new FormData()

      for (let [key, value] of Object.entries(this.form)) {
        if (value && key === 'birth_date') {
          value = value.toISOString().split('T')[0]
        }

        if (value) {
          data.append(key, value)
        }
      }

      if (this.file) {
        data.append('file', this.file)
      }

      this.isLoading = true

      if (this.user_id) {
        axios
          .post(`/update-profile/${this.user_id}`, data,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(this.handleResponse)
          .catch(this.handleError)
      }
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    }
  }
}
</script>
